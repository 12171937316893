import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Hr, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ev | The Mellow Malt'a Hoş Geldiniz - Rahat Köşeniz 
			</title>
			<meta name={"description"} content={"The Mellow Malt sadece bir pub değil, dostluk ve konforun kalbinde yer alan bir inziva yeridir. Rahatlamanın basit zevkini kutluyor"} />
			<meta property={"og:title"} content={"Ev | The Mellow Malt'a Hoş Geldiniz - Rahat Köşeniz "} />
			<meta property={"og:description"} content={"The Mellow Malt sadece bir pub değil, dostluk ve konforun kalbinde yer alan bir inziva yeridir. Rahatlamanın basit zevkini kutluyor"} />
			<meta property={"og:image"} content={"https://fronstailoc.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fronstailoc.com/img/favicon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fronstailoc.com/img/favicon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fronstailoc.com/img/favicon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fronstailoc.com/img/favicon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fronstailoc.com/img/favicon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fronstailoc.com/img/favicon.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="radial-gradient(50% 50% at 52.09% 50%, rgba(4, 8, 12, 0.4) 0%, #04080C 100%),url(https://fronstailoc.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="120px 0 88px 0"
			md-padding="140px 0 96px 0"
			sm-padding="60px 0 96px 0"
			quarkly-title="Hero-22"
			padding="88px 0 88px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 0px 0px 0px"
				lg-justify-content="center"
				width="100%"
				flex-direction="row"
				align-items="flex-start"
				lg-align-items="center"
				lg-flex-direction="column"
				display="flex"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
			>
				<Text
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
					color="#FFFFFF"
					font="--lead"
					width="100%"
					sm-margin="0px 0px 12px 0px"
					letter-spacing="2px"
				>
					Rahat Köşeniz{" "}
				</Text>
			</Box>
			<Box
				md-margin="0px 0px 0px 0px"
				width="100%"
				flex-direction="row"
				align-items="flex-end"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				lg-justify-content="center"
				display="flex"
				lg-align-items="center"
				padding="0px 0px 0px 0px"
				justify-content="space-between"
				lg-flex-direction="column"
			>
				<Text
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-width="100%"
					margin="0px 0px 0px 0px"
					lg-width="100%"
					lg-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					color="--light"
					width="30%"
					lg-text-align="center"
					lg-margin="0px 0px 20px 0px"
					font="--headline1"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Mellow Malt'a
				</Text>
				<Text
					lg-text-align="center"
					margin="0px 0px 0px 0px"
					color="#FFFFFF"
					font="--lead"
					width="30%"
					lg-width="100%"
				>
					The Mellow Malt sadece bir pub değil, dostluk ve konforun kalbinde yer alan bir inziva yeridir. Rahatlamanın basit zevkini kutluyor, günün koşuşturmasından sonra teselli arayanlar veya hafta sonunu rahat bir şekilde geçirmek isteyenler için bir sığınak sunuyoruz.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-5">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Benzersiz Özellikler
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 70px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://fronstailoc.com/img/4.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						object-fit="cover"
						sm-height="220px"
						height="600px"
						lg-height="400px"
						md-width="100%"
						md-height="450px"
					/>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-darkL1"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Chill Vibes: Uzun bir günün ardından rahatlamak için mükemmel bir atmosfer.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 30px 0px">
						<Image
							src="https://fronstailoc.com/img/2.jpg"
							display="block"
							max-width="100%"
							margin="0px 0px 25px 0px"
							height="300px"
							width="100%"
							object-fit="cover"
							sm-height="220px"
							lg-height="200px"
							md-height="350px"
						/>
						<Hr
							min-height="10px"
							margin="0px 0px 10px 0px"
							border-color="--color-darkL1"
							width="40px"
							border-width="2px 0 0 0"
						/>
						<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
							Sohbet Başlatıcı Köşeler: Diyalog başlatmak veya yeni arkadaşlar edinmek için tasarlanmıştır.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Image
							src="https://fronstailoc.com/img/3.jpg"
							display="block"
							max-width="100%"
							margin="0px 0px 25px 0px"
							height="300px"
							width="100%"
							object-fit="cover"
							sm-height="220px"
							lg-height="200px"
							md-height="350px"
						/>
						<Hr
							min-height="10px"
							margin="0px 0px 10px 0px"
							border-color="--color-darkL1"
							width="40px"
							border-width="2px 0 0 0"
						/>
						<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
							Hikaye Anlatıcısının Barı: Barmenlerimiz sadece bira doldurmakla kalmaz, aynı zamanda güneşin altındaki her şey hakkında sohbet etmeye hazır masal dokuyuculardır.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://fronstailoc.com/img/5.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						object-fit="cover"
						sm-height="220px"
						height="600px"
						lg-height="400px"
						md-width="100%"
						md-height="450px"
					/>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-darkL1"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Nazik Oyunlar: Stres olmadan eğlendirmek için tasarlanmış, bahislerin nakit değil fikirler olduğu 'Felsefe Pokerimiz' gibi rekabetçi olmayan etkinliklerin keyfini çıkarın.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" md-padding="80px 0 80px 0" quarkly-title="CTA/Lead/Form-9">
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				border-radius="40px"
				lg-flex-direction="column"
				align-items="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					lg-display="flex"
					lg-flex-direction="column"
					lg-align-items="center"
					lg-margin="0px 0px 40px 0px"
				>
					<Text
						color="--dark"
						text-align="left"
						font="normal 700 42px/1.2 --fontFamily-sans"
						sm-font="normal 700 36px/1.2 --fontFamily-sans"
						margin="0 0px 30px 0px"
						border-color="--color-darkL1"
					>
						Yaklaşan Etkinlikler
					</Text>
					<Text
						margin="0px 0 50px 0"
						text-align="left"
						font="--lead"
						color="#5c6267"
						lg-margin="0px 0 30px 0"
						sm-margin="0px 0 36px 0"
						lg-text-align="center"
						lg-max-width="640px"
					>
						- Mellow Mondays: Haftaya yumuşak caz ve indirimli biralarla başlayın.
						<br />
						- Perşembe günleri Trivia: Kasları değil, beyni gıdıklayan önemsiz şeylerle zihninizi meşgul edin.
						<br />
						- Arkadaşlık Cumaları: Bir arkadaşınızı getirin veya temalı bir kokteyl gecesinde yeni bir arkadaşla tanışın.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						justify-content="flex-start"
						sm-flex-direction="column"
						sm-align-items="center"
						margin="0px 0px 25px 0px"
					>
						<Button
							background="--color-green"
							font="normal 400 17px/1.5 --fontFamily-sans"
							padding="15px 34px 15px 34px"
							border-radius="50px"
							margin="0px 15px 0px 0px"
							sm-margin="0px 0 24px 0px"
							hover-color="#0044cc"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--color-green"
							hover-background="rgba(0, 68, 204, 0)"
							hover-transition="background-color 0.2s ease 0s"
							sm-display="block"
							href="/contact"
							type="link"
							text-decoration-line="initial"
						>
							Bize Ulaşın
						</Button>
					</Box>
				</Box>
				<Image
					src="https://fronstailoc.com/img/6.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					lg-width="100%"
					lg-height="auto"
					padding="0px 0px 0px 50px"
					lg-padding="0px 0px 0px 0"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});